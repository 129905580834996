
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow
} from '@ionic/vue';
import Header from '@/components/Header.vue';

export default  {
  name: 'History',
  components: { 
    Header, 
    IonPage, 
    IonContent, 
    IonCol, 
    IonGrid, 
    IonRow
  },
  data () {
    return {
      history: [],
      firstLoadDone: false
    }
  },
  computed: {
    hasEntries (): boolean {
      return (this as any).history.length
    },
    showNoEntries (): boolean {
      return (this as any).firstLoadDone && !(this as any).history.length
    }
  },
  methods: {
    since (time: any) {
      switch (typeof time) {
        case 'number':
          break;
        case 'string':
          time = +new Date(time);
          break;
        case 'object':
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      let timeFormats = [
        [60, 'Sekunden', 1], // 60
        [120, 'Vor 1 Minute', 'In 1 Minute'], // 60*2
        [3600, 'Minuten', 60], // 60*60, 60
        [7200, 'Vor 1 Stunde', 'In 1 Stunde'], // 60*60*2
        [86400, 'Stunden', 3600], // 60*60*24, 60*60
        [172800, 'Gestern', 'Morgen'], // 60*60*24*2
        [604800, 'Tage', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'Letzte Woche', 'Nächste Woche'], // 60*60*24*7*4*2
        [2419200, 'Wochen', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'Letzten Monat', 'Nächsten Monat'], // 60*60*24*7*4*2
        [29030400, 'Monaten', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Letztes Jahr', 'Nächstes Jahr'], // 60*60*24*7*4*12*2
        [2903040000, 'Jahre', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Letztes Jahrtausend', 'Nächstes Jahrtausend'], // 60*60*24*7*4*12*100*2
        [58060800000, 'Jahrtausende', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      let seconds = (+new Date() - time) / 1000,
        token = 'Vor',
        listChoice = 1;

      if (seconds == 0) {
        return 'Gerade jetzt'
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'In';
        listChoice = 2;
      }
      let i = 0,
        format;
        /* eslint-disable */
      while (format = timeFormats[i++])
        if (seconds < format[0]) {
          if (typeof format[2] == 'string')
            return format[listChoice];
          else
            return token + ' ' + Math.floor(seconds / format[2]) + ' ' + format[1];
        }
      return time;
    },
    loadHistory () {
      let history: any = localStorage.getItem('history');
      if (history !== null) {
          try {
              history = JSON.parse(history);
          } catch(err) {
              console.log(err);
              history = [];
          }
      } else {
          history = [];
      }

      if (history.length) {
        history = history.sort((a: any, b: any) => {
          if(a.timestamp > b.timestamp) return -1;
          if(a.timestamp < b.timestamp) return 1;
          return 0;
        });

        if (localStorage.getItem('jwt') === null) {
          history = history.filter((entry: any) => entry.public === true);
        }
        (this as any).history = history;
      }

      (this as any).firstLoadDone = true;
    },
    handleClick (entry: any) {
      console.log(entry);
      if (entry.type === 'news') {
        (this as any).$router.push({ path: `/news/${entry.id}` });
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave history');
    (this as any).history = [];
    (this as any).firstLoadDone = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered history');
    (this as any).loadHistory();
  },
  mounted () {
    const vm = (this as any);
    vm.loadHistory();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.loadHistory();
      }
    });
  }
}
